import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Section from "../components/Section";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Section>
      <h1>NOT FOUND!</h1>
      <p>You just hit a page that doesn&#39;t exist.</p>
    </Section>
  </Layout>
);

export default NotFoundPage;
